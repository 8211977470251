/* Sass Document */
/* CSS Document */
/*Estilos Inicio*/
/*Tipografias*/
@font-face {
  font-family: "Raleway-Regular";
  src: url(../../../../fonts/Raleway-Regular.eot), url(../../../../fonts/Raleway-Regular.otf), url(../../../../fonts/Raleway-Regular.ttf), url(../../../../fonts/Raleway-Regular.svg);
}

@font-face {
  font-family: "Raleway-Black";
  src: url(../../../../fonts/Raleway-Black.eot), url(../../../../fonts/Raleway-Black.otf), url(../../../../fonts/Raleway-Black.ttf), url(../../../../fonts/Raleway-Black.svg);
}

@font-face {
  font-family: "AfterNight";
  src: url(../../../../fonts/AfterNight.eot), url(../../../../fonts/AfterNight.otf), url(../../../../fonts/AfterNight.ttf), url(../../../../fonts/AfterNight.svg);
}

#containerServicios {
  width: 60%;
  border-radius: 50%;
  background-color: #1F87B0;
}

#containerServicios:after {
  content: '';
  padding-bottom: 100%;
  display: block;
}

#containerServicios img {
  max-height: 100px;
  padding: 15px;
  filter: invert(1);
}
