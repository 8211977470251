/* Sass Document */
/* CSS Document */
/*Estilos Nosotros*/
/*Tipografias*/
@font-face {
  font-family: "Raleway-Regular";
  src: url(../../../../fonts/Raleway-Regular.eot), url(../../../../fonts/Raleway-Regular.otf), url(../../../../fonts/Raleway-Regular.ttf), url(../../../../fonts/Raleway-Regular.svg);
}

@font-face {
  font-family: "Raleway-Black";
  src: url(../../../../fonts/Raleway-Black.eot), url(../../../../fonts/Raleway-Black.otf), url(../../../../fonts/Raleway-Black.ttf), url(../../../../fonts/Raleway-Black.svg);
}

@font-face {
  font-family: "AfterNight";
  src: url(../../../../fonts/AfterNight.eot), url(../../../../fonts/AfterNight.otf), url(../../../../fonts/AfterNight.ttf), url(../../../../fonts/AfterNight.svg);
}

#nosotros-container {
  background-color: #1A3B65;
  color: #FFFFFF;
  font-family: "Raleway-Regular";
}

#nosotros-container #nosotros {
  position: relative;
  top: -75px;
}

#nosotros-container #container-nosotros-text h2 {
  margin-top: 25px;
  font-family: "Raleway-Black";
}

#nosotros-container #container-nosotros-text p {
  margin-top: 15px;
  font-family: "Raleway-Regular";
}

#nosotros-container #container-nosotros-servicios {
  margin-top: 15px;
  margin-bottom: 30px;
}

#nosotros-container #container-nosotros-servicios button {
  font-family: "Raleway-Regular";
  text-transform: uppercase;
  background: none;
  border: 1px solid #FFFFFF;
  padding-left: 35px;
  padding-right: 35px;
  color: #FFFFFF;
  border-radius: 50px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

#nosotros-container #container-nosotros-servicios button:hover, #nosotros-container #container-nosotros-servicios button:active, #nosotros-container #container-nosotros-servicios button:focus {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #26B5B9;
  border: 1px solid #26B5B9;
}

@media only screen and (max-width: 500px) {
  #nosotros-container {
    background-color: #1A3B65;
    color: #FFFFFF;
    font-family: "Raleway-Regular";
  }
  #nosotros-container #container-nosotros-text h2 {
    margin-top: 50px;
    font-family: "Raleway-Black";
  }
}
