@font-face {
  font-family: 'iconP-lao';
  src:  url('fonts/iconP-lao.eot?visodl');
  src:  url('fonts/iconP-lao.eot?visodl#iefix') format('embedded-opentype'),
    url('fonts/iconP-lao.ttf?visodl') format('truetype'),
    url('fonts/iconP-lao.woff?visodl') format('woff'),
    url('fonts/iconP-lao.svg?visodl#iconP-lao') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconP-lao' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-IconoBranding:before {
  content: "\e900";
}
.icon-IconoEditorial:before {
  content: "\e901";
}
.icon-IconoMultimedia:before {
  content: "\e902";
}
.icon-IconoSocialMedia:before {
  content: "\e903";
}
.icon-IconoWeb:before {
  content: "\e904";
}
