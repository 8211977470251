@charset "UTF-8";
/* Sass Document */
/* CSS Document */
/*Estilos Inicio*/
/*Tipografias*/
@font-face {
  font-family: "Raleway-Regular";
  src: url(../../../fonts/Raleway-Regular.eot), url(../../../fonts/Raleway-Regular.otf), url(../../../fonts/Raleway-Regular.ttf), url(../../../fonts/Raleway-Regular.svg);
}

/*Diseño General de Estilos Navbar */
#container-logo-navbar {
  height: auto;
  width: auto;
  box-shadow: none;
  position: relative;
  background-color: none;
  left: auto;
  right: auto;
  transform: none;
  border-radius: none;
}

#logo-navbar {
  height: 40px;
  width: auto;
  transition: all 0.8s;
}

.bg-p-lao {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 5px 10px -3px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 5px 10px -3px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 5px 10px -3px rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link a {
  color: #8A4491 !important;
  text-decoration: none;
  font-family: "Raleway-Regular";
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.navbar-light .navbar-nav .nav-link a:hover, .navbar-light .navbar-nav .nav-link a:active, .navbar-light .navbar-nav .nav-link a:focus {
  border-bottom: solid 1px #E4B008;
  -webkit-transition: all 035s;
  transition: all 0.3s;
}

.navbar-collapse .active {
  border-bottom: solid 1px #E4B008 !important;
  -webkit-transition: all 035s;
  transition: all 0.3s;
}

/*Diseño Boton Menu*/
.navbar-toggler {
  padding: 0px !important;
}

#icono path, #icono line {
  stroke: #8A4491;
  stroke-width: 6px;
  stroke-linecap: round;
  fill: none;
}

.trigger {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 5;
  top: 0;
  left: 0;
}

button:focus {
  outline: none !important;
}

.navbar-toggler {
  border: none;
  background: transparent !important;
}

.navbar-toggler:hover, .navbar-toggler:active, .navbar-toggler:focus {
  border: none;
  background: transparent !important;
}

/*Termina Diseño Boton Menu*/
.active-nav-element {
  border-bottom: solid 1px #E4B008;
  -webkit-transition: all 035s;
  transition: all 0.3s;
}

/* ANIMATED X */
/* ANIMATED X COLLAPSED */
/* END ANIMATED X */
@media only screen and (min-width: 1200px) {
  .navbar {
    padding-bottom: 0px;
  }
  .navbar-brand {
    position: relative;
    padding: 0px;
    left: 36px;
    top: -18px;
  }
  #logo-navbar {
    position: relative;
    height: 60px;
    width: auto;
  }
  .bg-p-lao {
    padding-top: 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar {
    padding-bottom: 0px;
  }
  .navbar-brand {
    position: relative;
    padding: 0px;
    left: 36px;
    top: -18px;
  }
  #logo-navbar {
    position: relative;
    height: 60px;
    width: auto;
  }
  .bg-p-lao {
    padding-top: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar {
    padding-bottom: 0px;
  }
  .navbar-brand {
    position: relative;
    padding: 0px;
    left: 36px;
    top: -18px;
  }
  #logo-navbar {
    position: relative;
    height: 60px;
    width: auto;
  }
  .bg-p-lao {
    padding-top: 35px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  #container-logo-navbar {
    height: 120px;
    width: 120px;
    box-shadow: 0px 5px 10px -3px rgba(0, 0, 0, 0.5);
    position: absolute;
    background-color: #FFFFFF;
    left: 50%;
    right: 50%;
    transform: translate(-56.5%, -20%);
    border-radius: 100px;
  }
  #logo-navbar {
    height: 40px;
    width: auto;
    position: relative;
    transform: translateY(39px);
    -webkit-transform: translateY(39px);
    left: 10px;
  }
  .navbar {
    align-items: flex-start;
    justify-content: center;
  }
  .navbar-toggler {
    position: absolute;
    right: 25px;
    top: 15px;
    vertical-align: top !important;
  }
}

@media only screen and (max-width: 500px) {
  #container-logo-navbar {
    height: 120px;
    width: 120px;
    box-shadow: 0px 5px 10px -3px rgba(0, 0, 0, 0.5);
    position: absolute;
    background-color: #FFFFFF;
    left: 50%;
    right: 50%;
    transform: translate(-56.5%, -20%);
    border-radius: 100px;
  }
  #logo-navbar {
    height: 40px;
    width: auto;
    position: relative;
    transform: translateY(39px);
    -webkit-transform: translateY(39px);
    left: 10px;
  }
  .navbar {
    align-items: flex-start;
    justify-content: center;
  }
  .navbar-toggler {
    position: fixed;
    right: 25px;
    top: 15px;
    vertical-align: top !important;
  }
}
