@charset "UTF-8";
/* Sass Document */
/* CSS Document */
/*Estilos Inicio*/
/*Tipografias*/
@font-face {
  font-family: "Raleway-Regular";
  src: url(../../../fonts/Raleway-Regular.eot), url(../../../fonts/Raleway-Regular.otf), url(../../../fonts/Raleway-Regular.ttf), url(../../../fonts/Raleway-Regular.svg);
}

body {
  overflow-x: hidden;
}

.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  bottom: 80px !important;
  z-index: 1;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20p;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 10px !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: white !important;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black;
}

#inicio-container {
  margin-top: 85px;
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  #inicio-container {
    margin-top: 66px;
  }
}

@media only screen and (max-width: 500px) {
  #inicio-container {
    margin-top: 66px;
  }
}
