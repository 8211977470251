/* Sass Document */
/* CSS Document */
/*Estilos Footer*/
/*Tipografias*/
@font-face {
  font-family: "Raleway-Regular";
  src: url(../../../fonts/Raleway-Regular.eot), url(../../../fonts/Raleway-Regular.otf), url(../../../fonts/Raleway-Regular.ttf), url(../../../fonts/Raleway-Regular.svg);
}

@font-face {
  font-family: "Raleway-Black";
  src: url(../../../fonts/Raleway-Black.eot), url(../../../fonts/Raleway-Black.otf), url(../../../fonts/Raleway-Black.ttf), url(../../../fonts/Raleway-Black.svg);
}

@font-face {
  font-family: "AfterNight";
  src: url(../../../fonts/AfterNight.eot), url(../../../fonts/AfterNight.otf), url(../../../fonts/AfterNight.ttf), url(../../../fonts/AfterNight.svg);
}

#Footer #containerFooter {
  background-color: #26B5B9;
  padding-bottom: 25px;
  border-radius: 0px 50px 50px 0px;
  -webkit-box-shadow: 0px -5px 10px -3px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px -5px 10px -3px rgba(0, 0, 0, 0.5);
  box-shadow: 0px -5px 10px -3px rgba(0, 0, 0, 0.5);
}

#Footer #containerFooter #logoFooterContainer {
  background-color: #FFFFFF;
  padding: 25px;
  border-radius: 0px 0px 50px 50px;
}

#Footer #containerFooter #logoFooterContainer img {
  max-height: 80px;
}

#Footer #containerFooter p {
  font-family: "Raleway-Regular";
  color: #FFFFFF;
}

#Footer #containerFooter h2, #Footer #containerFooter h3 {
  color: #FFFFFF;
  font-family: "Raleway-Black";
  text-transform: uppercase;
}

#Footer #containerFooter .footer-icons-container {
  font-size: 25px;
  margin-top: 5px;
  display: inline-block;
  margin: 10px;
  padding: 5px;
  background-color: #FFFFFF;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

#Footer #containerFooter .footer-icons-container:hover, #Footer #containerFooter .footer-icons-container:active, #Footer #containerFooter .footer-icons-container:focus {
  background-color: #EDBA29;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

#Footer #containerFooter .footer-icons-container:hover svg, #Footer #containerFooter .footer-icons-container:active svg, #Footer #containerFooter .footer-icons-container:focus svg {
  color: #FFFFFF;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

#Footer #containerFooter .footer-icons-container svg {
  margin-left: 7.5px;
  color: #26B5B9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  #Footer #containerFooter {
    border-radius: 0px 0px 0px 0px;
  }
}

@media only screen and (max-width: 500px) {
  #Footer #containerFooter {
    border-radius: 0px 0px 0px 0px;
  }
}
