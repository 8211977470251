@charset "UTF-8";
/* Sass Document */
/* CSS Document */
/*Estilos Inicio*/
/*Tipografias*/
@font-face {
  font-family: "Raleway-Regular";
  src: url(../../../../fonts/Raleway-Regular.eot), url(../../../../fonts/Raleway-Regular.otf), url(../../../../fonts/Raleway-Regular.ttf), url(../../../../fonts/Raleway-Regular.svg);
}

@font-face {
  font-family: "Raleway-Black";
  src: url(../../../../fonts/Raleway-Black.eot), url(../../../../fonts/Raleway-Black.otf), url(../../../../fonts/Raleway-Black.ttf), url(../../../../fonts/Raleway-Black.svg);
}

@font-face {
  font-family: "AfterNight";
  src: url(../../../../fonts/AfterNight.eot), url(../../../../fonts/AfterNight.otf), url(../../../../fonts/AfterNight.ttf), url(../../../../fonts/AfterNight.svg);
}

h2 {
  font-family: "Raleway-Regular";
  color: #FFFFFF;
}

h2 span {
  font-family: "Raleway-Black";
  text-transform: uppercase;
}

h1 {
  font-family: "AfterNight";
  color: #0E5978;
  font-size: 80px;
}

.galeria-inicio-container,
.galeria-inicio {
  padding: 0px !important;
}

.galeria-inicio-element1 {
  height: 90vh;
  margin: 0px;
  padding-top: 60px;
  padding-bottom: 30px;
  background-color: #EDBA29 !important;
  border-radius: 0px 0px 0px 50px;
}

.galeria-inicio-element1 h2 {
  font-family: "Raleway-Regular";
  color: #FFFFFF;
}

.galeria-inicio-element1 h2 span {
  font-family: "Raleway-Black";
  text-transform: uppercase;
}

.galeria-inicio-element1 h1 {
  font-family: "AfterNight";
  color: #0E5978;
  font-size: 80px;
}

.galeria-inicio-element1 p {
  font-family: "Raleway-Regular";
  color: #FFFFFF;
  padding-left: 15px;
  padding-right: 15px;
}

.galeria-inicio-element2 {
  height: 90vh;
  margin: 0px;
  padding-top: 60px;
  padding-bottom: 30px;
  background-color: #26B5B9 !important;
  border-radius: 0px 0px 0px 50px;
}

.galeria-inicio-element2 h2 {
  font-family: "Raleway-Regular";
  color: #FFFFFF;
}

.galeria-inicio-element2 h2 span {
  font-family: "Raleway-Black";
  text-transform: uppercase;
}

.galeria-inicio-element2 h1 {
  font-family: "AfterNight";
  color: #FFFFFF;
  font-size: 80px;
}

.galeria-inicio-element2 p {
  font-family: "Raleway-Regular";
  color: #FFFFFF;
  padding-left: 15px;
  padding-right: 15px;
}

.slick-prev:before {
  font-size: 50px;
  content: '‹' !important;
}

.slick-next:before {
  font-size: 50px;
  content: '›' !important;
}

#container-curva-inicio #curva-inicio {
  top: -58px;
  max-height: 60px;
  position: absolute;
}

#container-curva-inicio #button-curva-inicio {
  position: absolute;
  padding: 0px;
  top: -40px;
  color: #26B5B9;
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
}

#container-curva-inicio #button-curva-inicio a {
  top: 0px;
  left: 0px;
  color: #26B5B9;
}

@media only screen and (max-width: 500px) {
  .galeria-inicio-element1 h1 {
    font-size: 50px;
  }
  .galeria-inicio-element2 h1 {
    font-size: 50px;
  }
  #container-curva-inicio #curva-inicio {
    top: -48px;
    max-height: 60px;
  }
}
